export const SET_CSRF_TOKEN = "SET_CSRF_TOKEN"

export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAIL = 'LOGOUT_FAIL';

export const AUTHENTICATED_SUCCESS = 'AUTHENTICATED_SUCCESS';
export const AUTHENTICATED_FAIL = 'AUTHENTICATED_FAIL';

export const LOAD_USER_PROFILE_SUCCESS = 'LOAD_USER_PROFILE_SUCCESS';
export const LOAD_USER_PROFILE_FAIL = 'LOAD_USER_PROFILE_FAIL';

export const UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS';
export const UPDATE_USER_PROFILE_FAIL = 'UPDATE_USER_PROFILE_FAIL';

export const USER_POST_SUCCESS = 'USER_POST_SUCCESS'
export const USER_POST_FAIL = 'USER_POST_FAIL'

export const USER_POST_LOAD_SUCCESS = 'USER_POST_LOAD_SUCCESS'
export const USER_POST_LOAD_FAIL = 'USER_POST_LOAD_FAIL'

export const POSTS_LOAD_SUCCESS = 'POSTS_LOAD_SUCCESS'
export const POSTS_LOAD_FAIL = 'POSTS_LOAD_FAIL'

export const ADD_SAMPLE_POST = 'ADD_SAMPLE_POST'
export const LOAD_MORE_POSTS = 'LOAD_MORE_POSTS'
export const GET_SINGLE_POST_SUCCESS = 'GET_SINGLE_POST_SUCCESS'
// export const ADD_SAMPLE_POST = 'ADD_SAMPLE_POST'
